@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.header {
  width: 100%;
  height: 15vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px lightgray solid;
}

.logo-image {
  width: 15vw;
  height: 80%;
  background-image: url("./images/newslogo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  left: 2%;
}

.header .app-title {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header .app-title h1 {
  font-size: 7vh;
  font-family: "Encode Sans Expanded", sans-serif;
}

main {
  height: 85vh;
  background-color: whitesmoke;
  margin: 0;
  font-size: 16pt;
}

.reactLoader {
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchbar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.searchbar input {
  padding: 1.5rem;
  border: 1px solid #666;
  border-radius: 3px;
  margin-left: 1rem;
  width: 30vw;
  height: 7vh;
  font: inherit;
}

.searchbar input[type="text"] {
  flex-grow: 1;
  margin-right: 0.5rem;
}

.searchResults {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 1rem;
  font-size: 1.05rem;
}

.result {
  padding-left: 1rem;
  margin-left: 2rem;
  margin-bottom: 0.3rem;
  border-radius: 3px;
  background-color: rgba(0, 166, 255, 0.03);
  list-style-type: decimal;
  list-style-position: outside;
}

.articletitle {
  display: inline;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.05rem;
}

.articletitle:hover {
  color: red;
}

.articledetails {
  display: flex;
  flex-direction: row;
  align-items: left;
  font-size: 0.9rem;
}

.errorMessage {
  margin-top: 20vh;
  color: #666;
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  gap: 5x;
}

.pagination .page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
}

.pagination .page-num:hover {
  background-color: #666;
  color: white;
}

.pagination .active {
  background-color: #666;
  color: white;
}
